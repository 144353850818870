import { FC } from 'react'
import Link from 'next/link'
import { Box, Text } from 'atomic'
import { TopBanner } from 'context/LinkContext'
import TrustpilotStars from 'components/trustpilot/TrustpilotStars'

type PropTypes = {
  topBanners: TopBanner[]
}

const TopBanners: FC<PropTypes> = ({ topBanners }) => {
  if (!topBanners || topBanners.length === 0) return null
  const topBanner = topBanners[0] // only show one for now

  return (
    <Box
      display="flex"
      style={{ backgroundColor: topBanner.backgroundColor }}
      paddingLeft="s"
      paddingRight="s"
      paddingTop="s"
      paddingBottom="s"
      justifyContent="center"
      gap="m"
      id="top-banner"
    >
      {topBanner.url && (
        <Link href={topBanner.url}>
          <Text margin="none" fontSize="s" fontWeight="semibold" style={{ color: topBanner.textColor }}>
            {topBanner.text}
          </Text>
        </Link>
      )}
      {!topBanner.url && (
        <Text margin="none" fontSize="s" fontWeight="semibold" style={{ color: topBanner.textColor }}>
          {topBanner.text}
        </Text>
      )}
      {topBanner.showTrustpilotStars && (
        <Box style={{ alignSelf: 'flex-end' }}>
          <TrustpilotStars width={84} height={20} />
        </Box>
      )}
    </Box>
  )
}

export default TopBanners
