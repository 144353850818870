import ExternalLink from 'components/externallink/ExternalLink'
import InternalLink from 'components/internallink/InternalLink'
import useAppLinks from 'hooks/useAppLinks'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { Button } from 'atomic'

import { useModalState } from '../../../context/ModalStateContext'
import { Theme } from '../../../styles/Theme'
import ActionDiv from '../../actiondiv/ActionDiv'
import DownArrow from '../../icons/DownArrow'
import OUTextLogo from '../../icons/OU_TextLogo'
import UpArrow from '../../icons/UpArrow'
import { defaultLocale } from '../../languagepicker/LanguageHelper'
import MenuButton from '../../menubutton/MenuButton'
import { DisableGlobalScroll } from '../../styled/Overlay'

import styles from './Header.module.scss'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { NavigationHeader, NavigationItem, TopBanner } from 'context/LinkContext'
import Link from 'next/link'
import TopBanners from 'components/builder_components/topbanners/TopBanners'

type PropTypes = {
  theme: Theme
  navLinks?: NavigationHeader[]
  topBanners?: TopBanner[]
}

const Header: FC<PropTypes> = ({ theme, navLinks, topBanners }) => {
  const router = useRouter()
  const { t } = useSafeTranslation()
  const { setShowGetAppModal } = useModalState()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [menuIsExpanded, setMenuIsExpanded] = useState(null)
  const [expandedNavHeaders, setExpandedNavHeaders] = useState(
    navLinks?.reduce((accumulator: NavigationHeader, currentValue: NavigationHeader) => {
      const key = currentValue.url
      accumulator[key] = false
      return accumulator
    }, navLinks[0])
  )
  const { activeOsUrl } = useAppLinks()

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const handleMediaQueryChange = (isDesktop: boolean): void => {
    if (isDesktop) {
      setMobileMenuOpen(false)
    }
  }

  const handleRouteChange = (): void => {
    setMobileMenuOpen(false)
  }

  useMediaQuery({ minWidth: theme.screenSizes.large }, undefined, handleMediaQueryChange)

  const navHeaderIsExpandable = (navHeader: NavigationHeader): boolean => {
    return navHeader.navItems?.length > 1
  }

  const navHeaderIsOpen = (navHeader: NavigationHeader): boolean => {
    return expandedNavHeaders[navHeader.url]
  }

  const toggleNavHeader = (navHeader: NavigationHeader): void => {
    setExpandedNavHeaders((prevState: NavigationHeader) => ({
      ...prevState,
      [navHeader.url]: !prevState[navHeader.url],
    }))
  }

  const headerIsActive = (navHeader: NavigationHeader): boolean => {
    const activeRouteWithLocale = router.locale === defaultLocale ? router.asPath : `/${router.locale}${router.asPath}`
    if (navHeader.navItems && navHeader.navItems?.length > 0) {
      const navHeaderIncludesActiveRoute = navHeader.navItems?.find((navItem: NavigationItem) => activeRouteWithLocale.endsWith(navItem.url))
      return navHeaderIncludesActiveRoute !== undefined
    }

    return activeRouteWithLocale.endsWith(navHeader.url)
  }

  const navItemIsActive = (navItem: NavigationItem): boolean => {
    const activeRouteWithLocale = router.locale === defaultLocale ? router.asPath : `/${router.locale}${router.asPath}`
    return activeRouteWithLocale.endsWith(navItem.url)
  }

  const renderArrow = (navHeader: NavigationHeader): JSX.Element => {
    return navHeaderIsOpen(navHeader) ? <UpArrow dark /> : <DownArrow dark />
  }

  const renderNavItems = (navHeader: NavigationHeader): JSX.Element[] => {
    const navItems = navHeader.navItems
    const builderNavItems = navItems.map((navItem: NavigationItem) => (
      <li key={navItem.url} className={styles.navLinkContainer} style={{ display: 'flex' }}>
        <Link href={navItem.url}>
          <span
            className={styles.navLink}
            onClick={() => (mobileMenuOpen ? setMobileMenuOpen(false) : setMenuIsExpanded(null))}
            role="button"
            tabIndex={0}
            onKeyDown={null}
            style={navItemIsActive(navItem) ? { fontWeight: 400 } : { fontWeight: 300 }}
          >
            {navItem.text}
          </span>
        </Link>
      </li>
    ))

    return builderNavItems
  }

  const renderDesktopMenu = (): JSX.Element => {
    return (
      <nav>
        <ul className={`${styles.desktopNav} largerThanLarge`}>
          {navLinks?.map((navHeader: NavigationHeader) => (
            <li
              onMouseEnter={() => setMenuIsExpanded(navHeader.url)}
              onMouseLeave={() => setMenuIsExpanded(null)}
              key={navHeader.url}
              className={navHeaderIsExpandable(navHeader) ? styles.expandable : null}
            >
              <div className={navHeader.campaign ? `${styles.navHeaderContainer} ${styles.campaign}` : styles.navHeaderContainer}>
                <Link href={navHeader.url}>
                  <span className={headerIsActive(navHeader) ? `${styles.navHeader} ${styles.active}` : styles.navHeader} title={navHeader.text}>
                    {navHeader.text}
                  </span>
                </Link>
              </div>
              <ul className={navHeader.url === menuIsExpanded ? styles.expanded : null}>{renderNavItems(navHeader)}</ul>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  const renderMobileMenu = (): JSX.Element => {
    return (
      <nav>
        <ul className={mobileMenuOpen ? `${styles.mobileNav} ${styles.open} smallerThanLarge` : styles.mobileNav}>
          {navLinks?.map((navHeader: NavigationHeader) => (
            <li
              key={navHeader.url}
              className={
                navHeaderIsExpandable(navHeader) ? (navHeaderIsOpen(navHeader) ? `${styles.open} ${styles.expandable}` : styles.expandable) : null
              }
            >
              {navHeaderIsExpandable(navHeader) ? (
                <ActionDiv className={styles.navHeaderContainer} onClick={() => toggleNavHeader(navHeader)} ariaLabel="expand navigation item">
                  <p className={styles.navHeader}>{navHeader.text}</p>
                  {renderArrow(navHeader)}
                </ActionDiv>
              ) : (
                <div className={navHeader.campaign ? `${styles.navHeaderContainer} ${styles.campaign}` : styles.navHeaderContainer}>
                  <Link href={navHeader.url}>
                    <span style={{ marginBottom: 16 }} className={styles.navHeader}>
                      {navHeader.text}
                    </span>
                  </Link>
                </div>
              )}
              <ul>{renderNavItems(navHeader)}</ul>
            </li>
          ))}
          <li className={styles.listItemForLogin}>
            <ExternalLink href="https://app.onceupon.photo/">
              <Button variant="secondary">{t('Authentication_signin_button')}</Button>
            </ExternalLink>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <>
      <DisableGlobalScroll show={mobileMenuOpen} />
      <header>
        <TopBanners topBanners={topBanners} />
        <div className={menuIsExpanded ? `${styles.container} ${styles.overlay}` : styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.logoContainer}>
              <InternalLink style={{ display: 'flex' }} href="/">
                <OUTextLogo aria-label="Link to start" width={120} height={39} />
              </InternalLink>
            </div>
            {renderDesktopMenu()}
            {renderMobileMenu()}
          </div>
          <div className={styles.rightContainer}>
            <div className={`${styles.row} smallerThanSmall`}>
              <ExternalLink href={activeOsUrl}>
                <Button variant="cta">{t('get_app_button_title')}</Button>
              </ExternalLink>
            </div>
            <div className={`${styles.row} largerThanLarge`}>
              <Button variant="cta" className={styles.textButton} onClick={() => setShowGetAppModal(true)}>
                <Illustration name="onceupon-logo" width={'24'} height={'24'} />
                {t('get_app_button_title')}
              </Button>
              <ExternalLink href={`https://app.onceupon.photo/authentication/signin/?lang=${router.locale}`}>
                <Button variant="secondary">{t('Authentication_signin_button')}</Button>
              </ExternalLink>
              <ExternalLink href={`https://app.onceupon.photo/authentication/signup/?lang=${router.locale}`}>
                <Button variant="cta">{t('authentication_signup_btn')}</Button>
              </ExternalLink>
            </div>
            <MenuButton menuOpen={mobileMenuOpen} setmenuOpen={() => setMobileMenuOpen(!mobileMenuOpen)} />
          </div>
        </div>
      </header>
    </>
  )
}

export default withTheme(Header)
