import { useRouter } from 'next/router'
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'

import ActionDiv from '../actiondiv/ActionDiv'
import DownArrow from '../icons/DownArrow'
import { languageStringFromLocale } from './LanguageHelper'
import { Link } from 'components/link/Link'
import { useOuCookies } from 'components/cookiebanner/CookieBanner'
import { PageAlternateLink, useLink } from 'context/LinkContext'
import * as css from './LanguagePicker.css'
import LanguageIcon from 'components/icons/LanguageIcon'
import { Box, Text } from 'atomic'
import UpArrow from 'components/icons/UpArrow'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import LanguageCheck from './language_check/LanguageCheck'

type PropTypes = {
  className: string
  pageAlternates?: PageAlternateLink[]
}

const fallbackLanguage = 'en-gb'

export const LanguagePicker: FC<PropTypes> = ({ className, pageAlternates }) => {
  const { setCookies, consentNecessary, NEXT_LOCALE } = useOuCookies()
  const router = useRouter()
  const { getLocalePath } = useLink()
  const node = useRef<HTMLDivElement>(null)
  const [isListOpen, toggleList] = useState(false)
  const { t } = useSafeTranslation()

  useEffect(() => {
    if (isListOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [isListOpen])

  const handleOutsideClick = (e: MouseEvent): void => {
    if (!node?.current.contains(e.target as Node)) {
      toggleList(false)
    }
  }

  const selectLanguageClick = (event: SyntheticEvent, href: string): void => {
    toggleList(false)
    event.preventDefault()
    const { pathname, query } = router
    // get rid of the protocol
    const url = href?.split('//')[1]

    // get rid of the host
    const indexOfSlash = url?.indexOf('/')
    const slug = indexOfSlash > -1 ? url?.substring(indexOfSlash).slice(1) : '/'

    // get the locale from the slug
    const [locale] = slug?.split('/')

    // get rest of the path from the slug
    const path = slug?.substring(slug?.indexOf('/'))

    const fallbackLocale = locale === '' ? fallbackLanguage : locale.toLowerCase()
    const fallbackPath = path === '' ? fallbackLanguage : path.toLowerCase()

    if (consentNecessary && NEXT_LOCALE !== fallbackLocale) {
      setCookies('NEXT_LOCALE', fallbackLocale)
    }

    // Special hack relating to the Kundo implementation.
    // router.push does not cause a refresh of the page,
    // which is required to reload the Kundo-script.
    // Therefore we use window.location.href instead.
    const enGbPath = getLocalePath(router.asPath, 'en-gb')
    if (enGbPath.toLowerCase().indexOf('/help/faq') > -1) {
      window.location.href = '/' + fallbackLocale + fallbackPath
    } else {
      router.push({ pathname, query }, fallbackPath, { locale: fallbackLocale, scroll: false })
    }
  }

  const linkMapper = (locale: string): JSX.Element => {
    const selected = router.locale.toLowerCase() === locale.toLowerCase()

    let href = pageAlternates?.find((link) => link.hreflang.toLowerCase() === locale.toLowerCase())?.href.toLowerCase()
    if (!href) {
      href = pageAlternates?.find((link) => link.hreflang === 'x-default')?.href
    }

    const host = href?.split('//')[1]
    const indexOfSlash = host?.indexOf('/')

    const slug = indexOfSlash > -1 ? host?.substring(host.indexOf('/')) : '/'
    return (
      <Link
        key={locale}
        href={`${slug}`}
        locale={false}
        onClick={(event) => selectLanguageClick(event, href)}
        className={css.languageLink[selected ? 'selected' : 'notSelected']}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'xs'} style={{ height: 40 }}>
          <Text fontSize={'s'} marginBottom={'none'}>
            {languageStringFromLocale(locale)}
          </Text>
          <LanguageCheck active={selected} />
        </Box>
      </Link>
    )
  }

  const zStyle = isListOpen ? css.zIndexStyle : ''

  return (
    <div ref={node} className={className ? `${css.container} ${className} ${zStyle}` : `${css.container} ${zStyle}`}>
      <ActionDiv className={css.header} onClick={() => toggleList(!isListOpen)} ariaLabel="language-picker">
        <LanguageIcon />
        <Text fontSize={'s'} fontWeight={'semibold'} marginBottom={'none'}>
          {t('footer_language_title')}
        </Text>
        {!isListOpen && <DownArrow dark strokeWidth={2} />}
        {isListOpen && <UpArrow dark strokeWidth={2} />}
      </ActionDiv>
      <Box
        position={'absolute'}
        borderRadius={'s'}
        backgroundColor="kantarell2"
        paddingX={'xxs'}
        className={css.listContainer}
        style={{ display: isListOpen ? 'block' : 'none' }}
      >
        {router.locales.map(linkMapper)}
      </Box>
    </div>
  )
}
